<template>
  <div class="love-timer">
    <div class="timer-title">💑 在一起已经</div>
    <div class="timer-content">
      <div class="time-item">
        <span class="number">{{ days }}</span>
        <span class="unit">天</span>
      </div>
      <div class="time-item">
        <span class="number">{{ hours }}</span>
        <span class="unit">小时</span>
      </div>
      <div class="time-item">
        <span class="number">{{ minutes }}</span>
        <span class="unit">分钟</span>
      </div>
      <div class="time-item">
        <span class="number">{{ seconds }}</span>
        <span class="unit">秒</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'

const startDate = new Date('2023-03-11 19:00:00')
const days = ref(0)
const hours = ref(0)
const minutes = ref(0)
const seconds = ref(0)

let timer = null

const calculateTime = () => {
  const now = new Date()
  const diff = now - startDate

  const d = Math.floor(diff / (1000 * 60 * 60 * 24))
  const h = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
  const m = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
  const s = Math.floor((diff % (1000 * 60)) / 1000)

  days.value = d
  hours.value = h
  minutes.value = m
  seconds.value = s
}

onMounted(() => {
  calculateTime()
  timer = setInterval(calculateTime, 1000)
})

onUnmounted(() => {
  if (timer) {
    clearInterval(timer)
  }
})
</script>

<style lang="scss" scoped>
.love-timer {
  background: linear-gradient(135deg, #ffd1d1 0%, #fff6f6 100%);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  margin: 20px auto;
  max-width: 600px;
  box-shadow: 0 4px 15px rgba(255, 182, 193, 0.2);
  border: 2px solid #ffb6c1;

  .timer-title {
    font-size: 1.5em;
    color: #ff69b4;
    margin-bottom: 15px;
    font-weight: bold;
  }

  .timer-content {
    display: flex;
    justify-content: center;
    gap: 15px;

    .time-item {
      background: white;
      padding: 10px;
      border-radius: 10px;
      min-width: 80px;
      box-shadow: 0 2px 8px rgba(255, 182, 193, 0.3);

      .number {
        display: block;
        font-size: 1.8em;
        font-weight: bold;
        color: #ff69b4;
      }

      .unit {
        display: block;
        font-size: 0.9em;
        color: #666;
        margin-top: 5px;
      }
    }
  }
}
</style>
